import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"

const TelebachennyaPage = ({
  data: { tvproviders, gadgets, satelite, t2, iptv },
}) => {
  return (
    <Layout>
      <SEO
        title="Інтернет-nелебачення, IPTV"
        description="Що таке Інтернет-телебачення або IPTV, провайдери, ціни, канали телебачення в Україні. Підключити Онлайн Телебачення."
        image=""
        url="/telebachennya/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Телебачення
              </li>
            </ol>
          </nav>
          <Row>
            <Col lg={8} md={6} sm={12} className="tv-hero">
              <h1 className="gradient-text mt-auto">Інтернет-телебачення</h1>
              <div className="hero-slogan mb-5">
                Дізнайтеся що таке інтернет-телебачення, які провайдери є в
                Україні, як підключити, налаштувати, та скільки це коштує?
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Img
                fluid={gadgets.fluid}
                alt="телебачення на будь яких пристроях"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-5">
        <p>
          Телебачення давно стало невіддільною частиною нашого повсякденного
          життя. Не обов'язково, як колись, йти до кінотеатру, щоб подивитися
          фільм, купувати газету, щоб дізнатися новини. Кожен день з'являється
          більше екранізацій за улюбленими книгами — вони доступні на екрані
          телевізора.
        </p>
        <p>
          З появою Інтернету телебачення змінилося, але не збирається зникати —
          з'являються нові формати цифрового телебачення, наприклад IPTV.
        </p>
        <h2 className="mt-5 mb-3">Що таке IPTV?</h2>
        <p>
          IPTV — Internet Protocol Television (протокол інтернет телебачення),
          тобто телебачення через інтернет. Переваги перед класичним або
          кабельним телебаченням, це інтерактивність:
        </p>
        <ul>
          <li>
            Самостійно вибирайте набір каналів, які бажаєте дивитися.
            Переглядайте відео на запит із каталогів з телепрограмою.
          </li>
          <li>
            Не пропустите завершальний епізод улюбленого серіалу, або
            вирішальний гол футбольної команди. Достатньо записати передачу та
            вибрати момент для подальшого перегляду.
          </li>
          <li>Автоматично ТВ-запис на 7 днів</li>
          <li>Можливість встановлення паузи, перемотування.</li>
          <li>Батьківський контроль.</li>
          <li>Обрані канали</li>
          <li>
            <p>
              Можливість підключити від 3-х до 4-х пристроїв, від однієї
              підписки:
            </p>
            <ul>
              <li>можете дивитися на Smart телевізорі,</li>
              <li>у додатках для смартфонів та планшетів,</li>
              <li>на будь-якому телевізорі з приставкою (медіаплеєром),</li>
              <li>на ПК на сайті.</li>
            </ul>
          </li>
        </ul>
        <h2 className="mt-5 mb-3">Провайдери інтернет-телебачення</h2>
        <Row>
          {tvproviders.nodes.map(provider => (
            <Col key={provider.id} className="tvprovider my-5" lg={4} sm={12}>
              <Img fixed={provider.logo.fixed} alt={provider.name} />
              <p className="mt-3">
                Дивись на {provider.devices} пристроях одночасно.
              </p>
              <Link to={`/telebachennya/${provider.seo.slug}/`}>
                Інструкція з підключення {provider.name}
              </Link>
            </Col>
          ))}
        </Row>
        <p>
          Інтернет-телебачення надає доступ до кращих ТВ-каналів, фільмів,
          серіалів та спортивних трансляцій, в найвищій якості зображення та
          звуку.
        </p>
        <p>
          Провайдери інтернет-телебачення — Trinity-tv, Sweet.tv, Megogo,
          OLL.TV, зібрали в одному місці контент, що задовольнить інтереси всієї
          родини:
        </p>
        <ul>
          <li>Кращі закордонні та українські телеканали.</li>
          <li>
            Понад 18000 фільмів і серіалів від провідних голлівудських,
            європейських і українських студій в відеотеці.
          </li>
          <li>
            Трансляції футболу, чемпіонатів з автоспорту, баскетболу, хокею,
            боксерських поєдинків та інших найгучніших подій світового спорту в
            HD якості.
          </li>
          <li>
            Всі сезони понад 100 найкращих серіалів планети від HBO, FOX,
            Showtime, CBS, Starz, Sony Pictures в HD якості та професійному
            озвучуванні.
          </li>
          <li>
            Дитячі канали, близько 8 000 годин мультфільмів і пізнавальних
            програм для маленьких глядачів.
          </li>
          <li>Музичні кліпи та онлайн радіостанцій.</li>
        </ul>
        <h3 className="mt-4 mb-2">Якість "картинки"</h3>
        <p>
          Щоб отримати доступ до IPTV потрібен інтернет. Від швидкості може
          залежати якість "картинки".
        </p>
        <p>
          Будь-який тариф провайдера Новий Телеком дозволяє дивитися телебачення
          в найкращій якості без затримок та завмирань.
        </p>
        <p>
          Потрібно звернути увагу, якщо в будинку інтернет роздає "слабкий"
          Wi-Fi роутер або дивитесь телебачення в іншій кімнаті за перешкодою,
          можуть спостерігатися зависання.
        </p>
        <p>
          Рекомендуємо підключення кабелем або проконсультуватися з фахівцями
          Newtelecom, як покращити якість сигналу в будинку.
        </p>
        <h2 className="my-5">Різновиди телебачення</h2>
        <h3 className="mt-5 mb-3">Супутникове телебачення</h3>
        <Img
          fluid={satelite.fluid}
          alt="супутникове телебаченння"
          className="tvarticle-image"
        />
        <p>
          В Україні супутникове телебачення використовується доволі широко,
          проте переважно у віддалених районах, де якість ефірного мовлення є
          незадовільною, а підключити інше телебачення немає можливості.
        </p>
        <h4>Переваги супутникового телебачення</h4>
        <ul className="tvpositives list-unstyled">
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Велика кількість каналів;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Разові витрати на встановлення (часто без щомісячної абонплати).
          </li>
        </ul>
        <h4>Недоліки супутникового телебачення</h4>
        <ul className="tvnegatives list-unstyled">
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Відсутність у відкритому доступі рейтингових телеканалів;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Вартість супутникового пакета каналів значно вища у порівнянні з
            аналогічним пакетом кабельного телебачення, а самовільне
            розкодування та/або шарінг* є незаконними діями, за які передбачена
            відповідальність;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Залежність від погодних умов;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Необхідність використання окремого ресивера ТБ для кожного
            телевізора;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Відсутність або низька якість сервісного обслуговування.
          </li>
        </ul>
        <h3 className="mt-5 mb-3">Телебачення Т2</h3>
        <Img
          fluid={t2.fluid}
          alt="телебаченння T2"
          className="tvarticle-image"
        />
        <p>
          Технологія ефірного цифрового телебачення, яка поступово
          впроваджується в Україні. Наразі Т2 надає користувачам можливість
          безкоштовно переглядати 32 ефірних телеканалів.
        </p>
        <h4>Переваги телебачення Т2</h4>
        <ul className="tvpositives list-unstyled">
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Для перегляду каналів необхідно мати тюнер Т2 та антену;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Телеканали безкоштовні;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Краща якість, ніж в ефірному аналоговому форматі.
          </li>
        </ul>
        <h4>Недоліки телебачення Т2</h4>
        <ul className="tvnegatives list-unstyled">
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Обмежена кількість телеканалів;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Відсутність рейтингових світових каналів;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Для кожного телевізора необхідно підключати окремий тюнер Т2;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Залежність від погодних умов;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Відсутність сервісної підтримки.
          </li>
        </ul>
        <h3 className="mt-5 mb-3">Інтернет-телебачення (IPTV)</h3>
        <Img
          fluid={iptv.fluid}
          alt="телебачення IPTV"
          className="tvarticle-image"
        />
        <p>
          Система, що дозволяє переглядати безліч телеканалів у будь-який час, у
          будь-якому місці, де є підключення до інтернету та одночасно з
          декількох екранів (телевізор, ПК, ноутбук, планшет, смартфон).
        </p>
        <h4>Переваги Інтернет-телебачення (IPTV)</h4>
        <ul className="tvpositives list-unstyled">
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Висока якість зображення (HD) та аудіо;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Можливість доступу до великої кількості каналів та самостійний
            відбір тих, які ви хотіли б подивитися;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Можливість перегляду на практично будь-якому пристрої з доступом до
            Інтернету;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Перегляд одночасно на декількох екранах (пристроях) від однієї
            підписки (абонплати);
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Можливість перегляду програм, що вже транслювалися на телебаченні (в
            ефірі);
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Можливість встановлення пауз або перемотування;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Функція батьківський контроль;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Можливість отримання послуги Інтернет та ТБ по одному дроту від
            одного провайдера;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            На Smart TV необхідно лише встановити безкоштовний додаток, на ПК,
            ноутбуках, планшетах, смартфонах – доступ до ТБ через сайт
            провайдера;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Всі ТВ-канали розділені за певними критеріями, що спрощує пошук
            потрібної передачі;
          </li>
          <li>
            <IoIosAddCircleOutline className="text-success" />
            Сервісна підтримка 24/7.
          </li>
        </ul>
        <h4>Недоліки Інтернет-телебачення (IPTV)</h4>
        <ul className="tvnegatives list-unstyled">
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Щомісячна абонплата;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Якщо звичайний телевізор (не SMART TV) - необхідний спеціальний
            медіа плеєр;
          </li>
          <li>
            <IoIosRemoveCircleOutline className="text-danger" />
            Обов’язковий доступ до мережі Інтернет.
          </li>
        </ul>
      </Container>
    </Layout>
  )
}

export default TelebachennyaPage

export const query = graphql`
  {
    tvproviders: allContentfulTvProvider(
      filter: { node_locale: { eq: "uk" }, isActive: { eq: true } }
    ) {
      nodes {
        id
        name
        isActive
        devices
        logo {
          fixed(width: 250) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        seo {
          slug
        }
      }
    }
    gadgets: contentfulAsset(
      id: { eq: "6362560a-bf47-5cac-ad1a-7031e77a4b0d" }
    ) {
      title
      fluid(maxWidth: 350) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    satelite: contentfulAsset(
      title: { eq: "pawel-czerwinski-xq2EsbMRPe4-unsplash" }
      node_locale: { eq: "uk" }
    ) {
      title
      fluid(
        maxWidth: 600
        cropFocus: CENTER
        quality: 85
        resizingBehavior: PAD
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    t2: contentfulAsset(title: { eq: "t2" }, node_locale: { eq: "uk" }) {
      title
      fluid(
        maxWidth: 600
        cropFocus: CENTER
        quality: 85
        resizingBehavior: PAD
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    iptv: contentfulAsset(
      title: { eq: "IPTV и интернет-телевидение" }
      node_locale: { eq: "uk" }
    ) {
      title
      fluid(
        maxWidth: 600
        cropFocus: CENTER
        quality: 85
        resizingBehavior: PAD
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
